"use client";
import React, { useState , useEffect} from "react";
import ShopSidebarCategories from "./ShopSidebarCategories";
import Pagination from "../elements/product/Pagination";
import GridViewProduct from "./GridViewProduct";
import ListViewProduct from "./ListViewProduct";
import ProductModal from "./ProductModal";
import { CartProductTypeTwo } from "../../interFace/interFace";
import { useParams } from "react-router-dom";
import { getProductsList } from "../../utils/ApiCalls";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ListIcon from "../../svg/ListIcon";
import GridIcon from "../../svg/GridIcon";
import {Helmet} from "react-helmet";

const ShopSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12); 
  const [products, setProducts] = useState<CartProductTypeTwo[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true); 

  const translations = useSelector((state: RootState) => state.user.translations);



  useEffect(() => {
    get_products(currentPage);
  }, [currentPage]);

  useEffect(() => {
    get_products(1);
  }, [searchValue, selectedCategoryIds]);

  const get_products = (page: number) => {
    
    setLoading(true);

    let params = [];

    if(searchValue != '')
        params.push({"keywords":searchValue})
    if(selectedCategoryIds.length > 0)
        params.push({"in_categories":selectedCategoryIds})
    getProductsList(page, perPage, params)
    .then((response) => {
        setProducts(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setlastPage(response.data.meta.last_page);
        setTotal(response.data.meta.total);
        setPerPage(response.data.meta.per_page);
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
    });
  };
  useEffect(() => {
    if(id) setSelectedCategoryIds([id as string]);
  });


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  };

  return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${translations?.nav?.website} - ${translations?.nav?.shop}`}</title>
      </Helmet>
      <section className="bd-shop__area pt-115 pb-85">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-4 col-lg-4">
              <div className="bd-sidebar__widget-warpper mb-60">
                <div className="bd-product__filters">
                  <ShopSidebarCategories 
                          selectedCategoryIds={selectedCategoryIds}
                          setSelectedCategoryIds={setSelectedCategoryIds}
                  />
                  {/*<ShopSidebarRetting />
                  <FlashBanner />*/}
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-8">
              <div className="row">
                <div className="col-xl-4">
                  <div className="bd-top__filter-search p-relative mb-30">
                    <form className="bd-top__filter-input" action="#">
                      <input
                        type="text"
                        placeholder={translations?.nav?.keywords}
                        value={searchValue}
                        onChange={handleInputChange}
                      />
                      <button>
                        <i className="fa-regular fa-magnifying-glass"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="bd-filter__tab-inner mb-30">
                    <div className="bd-top__filter">
                      <div className="bd-Product__tab pl-5">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              <GridIcon />
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="shop-filter-bar"
                              data-bs-toggle="tab"
                              data-bs-target="#profile"
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              <ListIcon />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bd-sort__type-filter">
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="bd-shop__wrapper">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="bd-trending__item-wrapper">
                          <div className="row">
                            <GridViewProduct products={products} loading={loading}  />
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="shop-filter-bar"
                      >
                        <div className="row">
                          <div className="col-xxl-12">
                            <ListViewProduct  products={products} loading={loading} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xxl-12">
                  <Pagination
                    totalPages={lastPage}
                    currentPage={currentPage}
                    setPage={setCurrentPage}
                    Pagination_space="d-flex justify-content-center mt-40  mb-45"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductModal />
    </>
  );
};

export default ShopSection;
