import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getCategoriesList } from "../../utils/ApiCalls";
import useGlobalContext from "../../hooks/use-context";
import ShopSidebarPreloader from "../../preloaders/ShopSidebarPreloader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { changeLanguage } from "@/redux/slices/userSlice";

interface ShopSidebarCategoriesProps {
  selectedCategoryIds: string[];
  setSelectedCategoryIds: (ids: string[]) => void;
}

const ShopSidebarCategories: React.FC<ShopSidebarCategoriesProps> = ({ selectedCategoryIds, setSelectedCategoryIds }) => {
  const [categories, setCategories] = useState<{ id: string; name: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const resultsPerPage = 10;

  const translations = useSelector((state: RootState) => state.user.translations);
  const language = useSelector((state: RootState) => state.user.language);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    setLoading(true);
    getCategoriesList(1, resultsPerPage,  [{ parent: "0" }])
      .then((response) => {
        const categoryList = response.data.data.map((elem: any) => ({
          id: elem.id,
          name: elem.translations[language].name,
        }));
        setCategories(categoryList);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      });
  };

  const handleCategoryChange = (categoryId: string) => {
    const updatedCategoryIds = selectedCategoryIds.includes(categoryId)
      ? selectedCategoryIds.filter(id => id !== categoryId) 
      : [...selectedCategoryIds, categoryId];

    setSelectedCategoryIds(updatedCategoryIds);
  };

  return (
    <div className="bd-filter__widget child-content-hidden">
      <h4 className="bd-filter__widget-title drop-btn">{translations?.nav?.category}</h4>
      <div className="bd-filter__content">
        {loading ? (
          <ShopSidebarPreloader end={7} />
        ) : (
          categories.map((item, index) => (
            <div key={index} className="bd-singel__rating">
              <input
                className="checkbox-box"
                type="checkbox"
                id={item.id}
                checked={selectedCategoryIds.includes(item.id)}
                onChange={() => handleCategoryChange(item.id)}
              />
              <label className="checkbox-label ms-2" htmlFor={item.id}>
                <div className="bd-product__icon custome-cursor text-capitalize">
                  {item.name}
                </div>
              </label>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ShopSidebarCategories;
